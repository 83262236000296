import { Component, OnDestroy, inject } from '@angular/core';
import { FormControl, FormGroup, NonNullableFormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SNACK_BAR_AUTO_DISMISS_MILLISECONDS } from '@insig-health/config/config';
import { FormValidatorsService } from '@insig-health/services/form-validators/form-validators.service';
import { PatientProfile, PatientProfileService } from '@insig-health/services/patient-profile/patient-profile.service';

type PharmacyInfoFormGroup = FormGroup<{
  pharmacyName: FormControl<string>;
  pharmacyFaxNumber: FormControl<string>;
}>;

@Component({
  selector: 'insig-booking-pharmacy-stand-alone-form',
  templateUrl: './pharmacy-stand-alone-form.component.html',
})
export class PharmacyStandAloneFormComponent implements OnDestroy {
  public static readonly PHARMACY_FORM_NAME = 'pharmacyInfoStandAloneForm';
  public static readonly PHARMACY_INFO_UPDATED_MESSAGE = 'Pharmacy information updated';
  public static readonly PHARMACY_INFO_FAILED_TO_SAVE_MESSAGE = 'Failed to save pharmacy information';

  private readonly formBuilder = inject(NonNullableFormBuilder);
  private readonly formValidatorsService = inject(FormValidatorsService);
  private readonly patientProfileService = inject(PatientProfileService);
  private readonly snackBar = inject(MatSnackBar);

  public readonly PHARMACY_NAME_FORM_CONTROL_NAME = 'pharmacyName';
  public readonly PHARMACY_FAX_NUMBER_FORM_CONTROL_NAME = 'pharmacyFaxNumber';

  public pharmacyStandAloneForm = this.formBuilder.group({
    [this.PHARMACY_NAME_FORM_CONTROL_NAME]: ['', [Validators.required]],
    [this.PHARMACY_FAX_NUMBER_FORM_CONTROL_NAME]: ['', [Validators.required, this.formValidatorsService.isPhoneNumberValidValidator(false)]],
  });

  public patientProfileSubscription = this.patientProfileService.getCurrentUserPatientProfile().subscribe((patientProfile) => {
    if (patientProfile) {
      this.populatePharmacyFormFromPatientProfile(this.pharmacyStandAloneForm, patientProfile);
    }
  });

  ngOnDestroy(): void {
    this.patientProfileSubscription.unsubscribe();
  }

  populatePharmacyFormFromPatientProfile(form: PharmacyInfoFormGroup, patientProfile: PatientProfile): void {
    form.patchValue({
      [this.PHARMACY_NAME_FORM_CONTROL_NAME]: patientProfile.pharmacyName,
      [this.PHARMACY_FAX_NUMBER_FORM_CONTROL_NAME]: patientProfile.pharmacyFax,
    });
  }

  async handleSubmit(): Promise<void> {
    if (this.pharmacyStandAloneForm.invalid) {
      return;
    }

    try {
      await this.patientProfileService.setCurrentUserPatientProfile({
        pharmacyName: this.pharmacyStandAloneForm.value[this.PHARMACY_NAME_FORM_CONTROL_NAME],
        pharmacyFax: this.pharmacyStandAloneForm.value[this.PHARMACY_FAX_NUMBER_FORM_CONTROL_NAME],
      });
      this.snackBar.open(PharmacyStandAloneFormComponent.PHARMACY_INFO_UPDATED_MESSAGE, undefined, { duration: SNACK_BAR_AUTO_DISMISS_MILLISECONDS });
    } catch (error) {
      console.error(error);
      this.snackBar.open(PharmacyStandAloneFormComponent.PHARMACY_INFO_FAILED_TO_SAVE_MESSAGE, undefined, { duration: SNACK_BAR_AUTO_DISMISS_MILLISECONDS });
    }
  }
}
