import { Component } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';

@Component({
  selector: 'insig-booking-pillway-learn-more-dialog',
  templateUrl: './pillway-learn-more-dialog.component.html',
})
export class PillwayLearnMoreDialogComponent {
  static readonly DIALOG_MAX_WIDTH = '600px';
  static readonly DIALOG_WIDTH = '100vw';

  static readonly DEFAULT_DIALOG_ID = 'insig-booking-pillway-learn-more-dialog-id';
  static readonly DEFAULT_DIALOG_CONFIG: MatDialogConfig = {
    id: PillwayLearnMoreDialogComponent.DEFAULT_DIALOG_ID,
    width: PillwayLearnMoreDialogComponent.DIALOG_WIDTH,
    maxWidth: PillwayLearnMoreDialogComponent.DIALOG_MAX_WIDTH,
  };
}
