import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { Subscription, firstValueFrom, filter, Subject, Observable } from 'rxjs';

import { AppointmentDraftUpdateRequest, AppointmentReservationService, DraftAppointmentSlot, DraftErrorCode, PaymentDetailsWithHealthCard, PaymentDetailsWithStripe } from '../../services/appointment-reservation/appointment-reservation.service';
import { BookingStep, BookingStepService } from '../../services/booking-step/booking-step.service';
import { DoctorSearchData, DoctorService } from '@insig-health/services/doctor/doctor.service';
import { HealthCardService } from '../../services/health-card/health-card.service';
import { PatientProfile, PatientProfileService } from '@insig-health/services/patient-profile/patient-profile.service';
import { StripeService } from '../../services/stripe/stripe.service';
import { StripeCustomer, StripeCustomerService } from '../../services/stripe-customer/stripe-customer.service';

import { CompanyBookingComponent } from '../company-booking/company-booking.component';
import { EditPatientFormDialogComponent } from './edit-patient-form-dialog/edit-patient-form-dialog.component';
import { BookingFlowVersion } from '@insig-health/api/doctor-booking-flow-api-v1';

import { FamilyMemberProfile, FamilyMemberService } from '@insig-health/services/family-member/family-member.service';
import { StripeElements } from '@stripe/stripe-js';
import { ProvinceBookingComponent } from '../province-booking/province-booking.component';
import { ProvinceService } from '@insig-health/services/province/province.service';
import { DateAndTimeService } from '@insig-health/services/date-and-time/date-and-time.service';
import { AnalyticsEvent, AnalyticsService } from '../../services/analytics/analytics.service';
import { SNACK_BAR_AUTO_DISMISS_MILLISECONDS } from '@insig-health/config/angular-material.config';
import { BillingType, BillingTypeService } from '../../services/billing-type/billing-type.service';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { GcpIpAuthService } from '@insig-health/gcp-ip/gcp-ip-auth.service';
import { FormValidatorsService } from '@insig-health/services/form-validators/form-validators.service';
import { AbstractControl } from '@angular/forms';
import { RegexService } from '@insig-health/services/regex/regex.service';
import { BillingRegionService } from '../../services/billing-region/billing-region.service';
import { InsigExpansionPanelState } from '@insig-health/components/lib/insig-expansion-panel/insig-expansion-panel.component';
import { EditFamilyMemberFormDialogComponent } from './edit-family-member-form-dialog/edit-family-member-form-dialog.component';
import { PrescriptionDeliveryService, PrescriptionDeliveryState } from '../../services/prescription-delivery/prescription-delivery.service';

export enum PaymentInformationState {
  NO_CARD,
  USE_EXISTING_CARD,
  UPDATE_CARD,
}

@Component({
  selector: 'insig-booking-confirm-booking',
  templateUrl: './confirm-booking.component.html',
  styleUrls: ['./confirm-booking.component.scss'],
})
export class ConfirmBookingComponent implements OnInit, OnDestroy {
  public static readonly DEFAULT_PAYMENT_ERROR_MESSAGE = 'An error occurred with the payment.';
  public static readonly STRIPE_PAYMENT_FORM_ID = 'stripePaymentForm';

  public static readonly BOOKING_INSTRUCTION_CUSTOM_HEADER = 'Instructions from Dr. ';
  public static readonly DEFAULT_BOOKING_INSTRUCTIONS_HEADER = 'Booking Instructions';
  public static readonly DEFAULT_BOOKING_INSTRUCTIONS = 'Please complete the intake form 15 minutes before the appointment start, otherwise your appointment may be subject to being canceled.';
  public static readonly DEFAULT_BOOKING_INSTRUCTIONS_FOOTER = 'If you require in-person assessment, please do not book this appointment and visit your nearest clinic or emergency care centre.';

  public static readonly INVALID_HEALTHCARD_ERROR_MESSAGE = 'Your health card number is missing or invalid. Please enter a valid health card number.';
  public static readonly INVALID_HEALTHCARD_CONTACT_MINISTRY_ERROR_MESSAGE = 'Your health card is listed as invalid. Please confirm that the number and version code (if applicable) is uploaded correctly, or contact your Ministry of Health to investigate why it is ineligible.';
  public static readonly INCOMPLETE_PROFILE_ERROR_MESSAGE = 'Please verify your profile to continue.';
  public static readonly INCOMPLETE_FAMILY_MEMBER_PROFILE_ERROR_MESSAGE = 'Please verify the family member\'s profile to continue.';
  public static readonly NO_PAYMENT_METHOD_ERROR_MESSAGE = 'Please enter a payment method to continue.';

  private readonly gcpIpAuthService = inject(GcpIpAuthService);
  private readonly appointmentReservationService = inject(AppointmentReservationService);
  private readonly bookingStepService = inject(BookingStepService);
  private readonly billingTypeService = inject(BillingTypeService);
  private readonly billingRegionService = inject(BillingRegionService);
  private readonly dialog = inject(MatDialog);
  private readonly doctorDataSearchWrapper = inject(DoctorService);
  private readonly familyMemberService = inject(FamilyMemberService);
  private readonly formValidatorsService = inject(FormValidatorsService);
  private readonly healthCardService = inject(HealthCardService);
  private readonly patientProfileService = inject(PatientProfileService);
  private readonly route = inject(ActivatedRoute);
  private readonly snackBar = inject(MatSnackBar);
  private readonly stripeCustomerService = inject(StripeCustomerService);
  private readonly stripeService = inject(StripeService);
  private readonly provinceService = inject(ProvinceService);
  private readonly dateAndTimeService = inject(DateAndTimeService);
  private readonly analyticsService = inject(AnalyticsService);
  private readonly regexService = inject(RegexService);
  private readonly prescriptionDeliveryService = inject(PrescriptionDeliveryService);

  public ConfirmBookingComponent = ConfirmBookingComponent;

  public InsigExpansionPanelState = InsigExpansionPanelState;

  public PaymentInformationState = PaymentInformationState;
  public paymentInformationState = PaymentInformationState.NO_CARD;

  public accountHolderProfile$: Observable<PatientProfile | undefined> = new Observable<PatientProfile | undefined>();
  public selectedFamilyMemberProfile: FamilyMemberProfile | undefined;
  public isBookForSomeoneElseSelected = false;
  public doctorSearchData = {} as DoctorSearchData;
  public draftAppointment = {} as DraftAppointmentSlot;
  public serviceType = '';
  public showSpinner = false;
  public lastFourDigitsOfExistingPaymentCard: string | undefined;
  public servicePrice: number | undefined;
  public discountedPrice: number | undefined;

  public isFamilyDoctorInvalid = false;
  public isHealthCardInvalid = false;
  public isBookingInstructionsCheckboxChecked = false;
  public isDraftAppointmentUpdating = false;
  public updateFamilyMembersSubject = new Subject<void>();

  public isCompanyTiaHealth = this.isTiaHealthFlow(this.route);

  public prescriptionDeliveryState$ = this.prescriptionDeliveryService.getPrescriptionDeliveryState();

  private isLoggedInSubscription: Subscription;
  private draftAppointmentSubscription: Subscription;

  private nonBlockingErrorCodes = [
    DraftErrorCode.DISCOUNT_CODE_DOES_NOT_EXIST,
  ];

  constructor(
  ) {
    this.isLoggedInSubscription = this.gcpIpAuthService.isLoggedIn().subscribe((isLoggedIn) => {
      if (!isLoggedIn) {
        this.navigateToChooseTimePage(this.draftAppointment);
      }
    });

    this.draftAppointmentSubscription = this.appointmentReservationService.getCurrentReservedAppointmentSlot().subscribe((draftAppointment) => {
      if (draftAppointment) {
        this.draftAppointment = draftAppointment;
        this.isHealthCardInvalid = this.healthCardService.isHealthCardValidInDraft(this.draftAppointment);
        if (this.draftAppointment.errorCodes.includes(DraftErrorCode.PATIENT_PROFILE_INCOMPLETE)) {
          this.handlePatientProfileIncompleteErrorCode();
        }
      }
    });
  }

  async ngOnInit(): Promise<void> {
    try {
      this.accountHolderProfile$ = this.patientProfileService.getCurrentUserPatientProfile();
      this.draftAppointment = await firstValueFrom(this.appointmentReservationService.getCurrentReservedAppointmentSlot()
        .pipe(filter((optionalDraftAppointment): optionalDraftAppointment is DraftAppointmentSlot => optionalDraftAppointment != undefined)));
      this.doctorSearchData = await this.doctorDataSearchWrapper.getDoctorById(this.draftAppointment.doctorId);
      this.serviceType = this.getServiceTypeById(this.draftAppointment.serviceId, this.doctorSearchData);
      this.servicePrice = this.draftAppointment.price;
      const accountHolderProfile = await firstValueFrom(this.accountHolderProfile$);
      if (accountHolderProfile) {
        this.lastFourDigitsOfExistingPaymentCard = await this.getLastFourDigitsOfExistingPaymentCard(accountHolderProfile.uid);
        if (this.lastFourDigitsOfExistingPaymentCard) {
          this.paymentInformationState = PaymentInformationState.USE_EXISTING_CARD;
        }

        await this.patchDraftAppointment(this.draftAppointment.appointmentId, { patientId: accountHolderProfile.uid });
      }

      this.handleDraftAppointmentErrors(this.draftAppointment);
    } catch (error) {
      console.error(error);
      this.navigateToChooseDoctorPage();
      throw error;
    }
  }

  ngOnDestroy(): void {
    this.isLoggedInSubscription.unsubscribe();
    this.draftAppointmentSubscription.unsubscribe();
  }

  getAccountHolderUid(): string {
    const accountHolderUid = this.gcpIpAuthService.getCurrentUser()?.uid;
    if (accountHolderUid === undefined) {
      throw new Error('Not logged in');
    }
    return accountHolderUid;
  }

  async getLastFourDigitsOfExistingPaymentCard(patientUid: string): Promise<string | undefined> {
    try {
      const stripeCustomer = await this.stripeCustomerService.getStripeCustomer(patientUid);
      return stripeCustomer.sources[0]?.lastFourDigits;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  getServiceTypeById(serviceId: string, doctorSearchData: DoctorSearchData): string {
    const service = doctorSearchData.service.appointments.find((appointment) => appointment.id === serviceId);
    if (service === undefined) {
      throw new Error(`Could not find service with id ${serviceId}`);
    }

    return service.type;
  }

  async handleSelectedFamilyMemberChange(selectedFamilyMemberProfile: FamilyMemberProfile | undefined): Promise<void> {
    if (!selectedFamilyMemberProfile) {
      return;
    }

    this.selectedFamilyMemberProfile = selectedFamilyMemberProfile;

    const appointmentDraftUpdateRequest: AppointmentDraftUpdateRequest = {
      familyMemberId: selectedFamilyMemberProfile.familyMemberId,
    };
    await this.patchDraftAppointment(this.draftAppointment.appointmentId, appointmentDraftUpdateRequest);
    this.isBookingInstructionsCheckboxChecked = false;
  }

  async patchDraftAppointment(draftAppointmentId: string, appointmentDraftUpdateRequest: AppointmentDraftUpdateRequest): Promise<void> {
    this.isDraftAppointmentUpdating = true;
    try {
      await this.appointmentReservationService.updateReservedAppointmentSlot(draftAppointmentId, appointmentDraftUpdateRequest);

      if (this.draftAppointment.tooManyAppointmentsError.tooManyAppointmentsInOneDay) {
        this.snackBar.open('You have reached the maximum number of bookings for the chosen day.  Please select a different day to book another appointment', undefined, { duration: SNACK_BAR_AUTO_DISMISS_MILLISECONDS });
      } else {
        this.handleDraftAppointmentErrors(this.draftAppointment);
      }
    } catch (error) {
      console.error(error);
      this.snackBar.open('An error occurred. Please try again.', undefined, { duration: SNACK_BAR_AUTO_DISMISS_MILLISECONDS });
    }
    this.isDraftAppointmentUpdating = false;
  }

  async handleMyselfButtonChecked(accountHolderProfile: PatientProfile | undefined): Promise<void> {
    if (!accountHolderProfile) {
      return;
    }

    this.isBookingInstructionsCheckboxChecked = false;
    this.isBookForSomeoneElseSelected = false;
    this.selectedFamilyMemberProfile = undefined;

    await this.patchDraftAppointment(this.draftAppointment.appointmentId, { patientId: accountHolderProfile.uid });
  }

  async handleHealthCardSaved(): Promise<void> {
    const accountHolderProfile = await firstValueFrom(this.accountHolderProfile$);
    if (accountHolderProfile) {
      await this.patchDraftAppointment(this.draftAppointment.appointmentId, { patientId: accountHolderProfile.uid });
    }
  }

  handleSomeoneElseButtonChecked(): void {
    this.isBookForSomeoneElseSelected = true;
    this.isBookingInstructionsCheckboxChecked = false;
  }

  handleBackButtonClicked(): void {
    if (window.history.length > 1) {
      window.history.back();
    } else {
      this.navigateToChooseTimePage(this.draftAppointment);
    }
  }

  navigateToChooseTimePage(draftAppointment: DraftAppointmentSlot): void {
    const companyBookingRoute = this.bookingStepService.getActivatedRouteAncestorOfComponentType(this.route, [CompanyBookingComponent]);
    const region = this.billingRegionService.getBillingRegion(draftAppointment.province, this.billingTypeService.parseBillingType(draftAppointment.billingType));
    this.bookingStepService.jumpToStep(BookingStep.CHOOSE_TIME, {
      navigationExtras: {
        relativeTo: companyBookingRoute,
      },
      pathParams: {
        region,
        doctorId: draftAppointment.doctorId,
        serviceId: draftAppointment.serviceId,
      },
    });
  }

  navigateToChooseDoctorPage(): void {
    const companyBookingRoute = this.bookingStepService.getActivatedRouteAncestorOfComponentType(this.route, [CompanyBookingComponent]);
    const provinceBookingRoute = this.bookingStepService.getActivatedRouteAncestorOfComponentType(this.route, [ProvinceBookingComponent]);
    const provinceAbbreviation = provinceBookingRoute.snapshot.params.provinceAbbreviation;
    const region = this.billingRegionService.getBillingRegion(provinceAbbreviation, BillingType.PRIVATE);
    this.bookingStepService.jumpToStep(BookingStep.CHOOSE_DOCTOR, {
      navigationExtras: { relativeTo: companyBookingRoute },
      pathParams: { region },
    });
  }

  async handleEditButtonClicked(accountHolderProfile: PatientProfile | undefined): Promise<void> {
    if (accountHolderProfile === undefined) {
      return;
    }
    this.openEditPatientDialog(accountHolderProfile);
  }

  async openEditPatientDialog(accountHolderProfile: PatientProfile): Promise<void> {
    const dialogRef = this.dialog.open<EditPatientFormDialogComponent, { patientProfile: PatientProfile }, boolean>(EditPatientFormDialogComponent, {
      data: {
        patientProfile: accountHolderProfile,
      },
      maxWidth: EditPatientFormDialogComponent.DIALOG_MAX_WIDTH,
      width: EditPatientFormDialogComponent.DIALOG_WIDTH,
    });

    const isProfileUpdated = await firstValueFrom(dialogRef.afterClosed());
    if (isProfileUpdated) {
      await this.patchDraftAppointment(this.draftAppointment.appointmentId, { patientId: accountHolderProfile.uid });
    }
  }

  openEditFamilyMemberDialog(familyMemberProfile: FamilyMemberProfile): void {
    this.dialog.open<EditFamilyMemberFormDialogComponent, { familyMemberProfile: FamilyMemberProfile }, boolean>(
      EditFamilyMemberFormDialogComponent, {
        data: { familyMemberProfile },
        maxWidth: EditFamilyMemberFormDialogComponent.DIALOG_MAX_WIDTH,
        width: EditFamilyMemberFormDialogComponent.DIALOG_WIDTH,
      });
  }

  async handleBookNowButtonClicked(): Promise<void> {
    try {
      if (this.isBookForSomeoneElseSelected) {
        if (this.selectedFamilyMemberProfile !== undefined) {
          await this.handleBookNowButtonClickedForSomeoneElse(this.selectedFamilyMemberProfile);
        }
      } else {
        const accountHolderProfile = await firstValueFrom(this.accountHolderProfile$);
        if (accountHolderProfile) {
          await this.handleBookNowButtonClickedForMyself(accountHolderProfile);
        }
      }
    } catch (error) {
      console.error(error);
      await this.handleBookNowButtonClickedError(error);
      this.showSpinner = false;
    }
  }

  private async handleBookNowButtonClickedError(error: unknown): Promise<void> {
    if (error instanceof Error) {
      this.snackBar.open(error.message, undefined, { duration: SNACK_BAR_AUTO_DISMISS_MILLISECONDS });
    } else if (error instanceof HttpErrorResponse) {
      const errorCode = this.appointmentReservationService.parseDraftErrorCode(error.error?.errorCode ?? '');
      if (errorCode === DraftErrorCode.DRAFT_INVALID_FOR_DOCTOR) {
        this.snackBar.open(error.error.errorMessage, undefined, { duration: SNACK_BAR_AUTO_DISMISS_MILLISECONDS });
        await this.appointmentReservationService.deleteAppointmentDraft(this.draftAppointment.appointmentId);
        this.navigateToChooseTimePage(this.draftAppointment);
      } else if (errorCode === DraftErrorCode.INVALID_HEALTHCARD) {
        this.snackBar.open(ConfirmBookingComponent.INVALID_HEALTHCARD_ERROR_MESSAGE, undefined, { duration: SNACK_BAR_AUTO_DISMISS_MILLISECONDS });
        this.isHealthCardInvalid = true;
      } else if (errorCode === DraftErrorCode.INVALID_HEALTHCARD_CONTACT_MINISTRY) {
        this.snackBar.open(ConfirmBookingComponent.INVALID_HEALTHCARD_CONTACT_MINISTRY_ERROR_MESSAGE, undefined, { duration: SNACK_BAR_AUTO_DISMISS_MILLISECONDS });
        this.isHealthCardInvalid = true;
      } else if (errorCode === DraftErrorCode.PATIENT_PROFILE_INCOMPLETE) {
        await this.handlePatientProfileIncompleteErrorCode();
      } else if (error.status === 404) {
        this.snackBar.open('Your time slot has expired. Please select a new one.', undefined, { duration: SNACK_BAR_AUTO_DISMISS_MILLISECONDS });
        this.navigateToChooseTimePage(this.draftAppointment);
      } else if (error.error?.errorMessage !== undefined) {
        this.snackBar.open(error.error.errorMessage, undefined, { duration: SNACK_BAR_AUTO_DISMISS_MILLISECONDS });
        this.navigateToChooseDoctorPage();
      }
    }
  }

  private async handlePatientProfileIncompleteErrorCode(): Promise<void> {
    if (!this.isBookForSomeoneElseSelected) {
      const accountHolderProfile = await firstValueFrom(this.accountHolderProfile$);
      if (accountHolderProfile) {
        this.snackBar.open(ConfirmBookingComponent.INCOMPLETE_PROFILE_ERROR_MESSAGE, undefined, { duration: SNACK_BAR_AUTO_DISMISS_MILLISECONDS });
        this.openEditPatientDialog(accountHolderProfile);
      }
    } else if (this.selectedFamilyMemberProfile) {
      this.snackBar.open(ConfirmBookingComponent.INCOMPLETE_FAMILY_MEMBER_PROFILE_ERROR_MESSAGE, undefined, { duration: SNACK_BAR_AUTO_DISMISS_MILLISECONDS });
      this.openEditFamilyMemberDialog(this.selectedFamilyMemberProfile);
    }
  }

  async handleBookNowButtonClickedForMyself(accountHolderProfile: PatientProfile): Promise<void> {
    const healthCardPaymentDetails = await this.getAccountHolderHealthCardPaymentDetails(accountHolderProfile);
    await this.bookAppointment(healthCardPaymentDetails);
  }

  async handleBookNowButtonClickedForSomeoneElse(familyMemberProfile: FamilyMemberProfile): Promise<void> {
    const healthCardPaymentDetails = await this.getFamilyMemberHealthCardPaymentDetails(familyMemberProfile);
    await this.bookAppointment(healthCardPaymentDetails);
  }

  async bookAppointment(healthCardPaymentDetails: PaymentDetailsWithHealthCard): Promise<void> {
    this.showSpinner = true;

    const isPayingPrivately = this.draftAppointment.billingType === BillingType.PRIVATE || this.draftAppointment.isServiceCharged;

    if (isPayingPrivately) {
      const uid = this.getAccountHolderUid();
      const customer = await this.stripeCustomerService.getCustomerResponse(uid);
      if (customer.sources.length === 0) {
        this.showSpinner = false;
        throw new Error('Please enter a payment method to continue.');
      }
    }

    const currentReservedAppointmentSlot = await firstValueFrom(this.appointmentReservationService.getCurrentReservedAppointmentSlot());
    try {
      this.appointmentReservationService.clearCurrentReservedAppointmentSlot();
      let appointmentBooking;

      if (isPayingPrivately) {
        const stripePaymentDetails = this.getStripePaymentDetails();
        appointmentBooking = await this.appointmentReservationService.confirmReservedAppointmentSlotWithStripe(this.draftAppointment.appointmentId, stripePaymentDetails);
      } else {
        appointmentBooking = await this.appointmentReservationService.confirmReservedAppointmentSlotWithHealthCard(this.draftAppointment.appointmentId, healthCardPaymentDetails);
      }

      this.analyticsService.logEvent(AnalyticsEvent.BOOKING_SUCCESS);
      if (appointmentBooking.surveyUrl) {
        this.navigateToSurvey(appointmentBooking.surveyUrl);
      } else {
        this.navigateToBookingSuccessPage(this.draftAppointment, this.serviceType);
      }
    } catch (error) {
      this.analyticsService.logEvent(AnalyticsEvent.BOOKING_FAILURE);
      this.snackBar.open((error as HttpErrorResponse).error?.errorMessage ?? 'An unknown error occurred. Please try again or contact support.', undefined, { duration: SNACK_BAR_AUTO_DISMISS_MILLISECONDS });
      this.showSpinner = false;
      if (currentReservedAppointmentSlot) {
        try {
          const updatedReservedAppointmentSlot = await this.appointmentReservationService.getReservedAppointmentSlot(currentReservedAppointmentSlot.appointmentId);
          this.appointmentReservationService.setCurrentReservedAppointmentSlot(updatedReservedAppointmentSlot);
        } catch (httpErrorResponse) {
          if (httpErrorResponse instanceof HttpErrorResponse && httpErrorResponse.status === 404) {
            throw error;
          }
        }
      }
      throw error;
    }
  }

  async getAccountHolderHealthCardPaymentDetails(accountHolderProfile: PatientProfile): Promise<PaymentDetailsWithHealthCard> {
    return {
      planId: undefined,
      healthCardProvince: this.provinceService.parseQueryParamProvince(this.healthCardService.parseHealthCardProvince(accountHolderProfile.province)),
      healthCardNumber: accountHolderProfile.healthCardNumber,
      bookingFlowVersion: BookingFlowVersion.REFRESHED_1,
      timeZoneId: this.dateAndTimeService.getLocalTimeZone(),
    };
  }

  async getFamilyMemberHealthCardPaymentDetails(familyMemberProfile: FamilyMemberProfile): Promise<PaymentDetailsWithHealthCard> {
    return {
      planId: undefined,
      familyMemberId: familyMemberProfile.familyMemberId,
      healthCardProvince: this.provinceService.parseQueryParamProvince(this.healthCardService.parseHealthCardProvince(familyMemberProfile.province)),
      healthCardNumber: familyMemberProfile.healthCardNumber,
      bookingFlowVersion: BookingFlowVersion.REFRESHED_1,
      timeZoneId: this.dateAndTimeService.getLocalTimeZone(),
    };
  }

  async makeStripePayment(stripePaymentDetails: PaymentDetailsWithStripe, stripeElements: StripeElements, currentReservedAppointmentSlot: DraftAppointmentSlot): Promise<void> {
    if (this.serviceType && this.draftAppointment) {
      const stripePaymentIntentClientSecret = await this.stripeService.getStripeClientSecret();

      const stripeRedirectQueryParameters = {
        doctorId: currentReservedAppointmentSlot.doctorId,
        appointmentMedium: currentReservedAppointmentSlot.serviceMedium,
        appointmentId: currentReservedAppointmentSlot.appointmentId,
        serviceId: currentReservedAppointmentSlot.serviceId,
        startTime: currentReservedAppointmentSlot.startTime,
        serviceType: this.serviceType,
        stripePaymentIntentClientSecret: stripePaymentIntentClientSecret,
        stripePaymentDetails: JSON.stringify(stripePaymentDetails),
      };
      try {
        await this.stripeService.makeStripePayment(stripeElements, this.stripeService.stripeRedirectUrl + `/${currentReservedAppointmentSlot.companyId}`, stripeRedirectQueryParameters);
      } catch (error) {
        let errorMessage = undefined;
        if (error instanceof Error) {
          errorMessage = error.message;
        }
        this.emitErrorMessageForPayment(errorMessage);
      }
    }
  }

  getStripePaymentDetails(): PaymentDetailsWithStripe {
    return {
      familyMemberId: this.selectedFamilyMemberProfile?.familyMemberId,
      stripePaymentIntentId: 'mockStripePaymentIntentId',
      bookingFlowVersion: BookingFlowVersion.REFRESHED_1,
      timeZoneId: this.dateAndTimeService.getLocalTimeZone(),
    };
  }

  emitErrorMessageForPayment(errorMessage?: string): void {
    this.snackBar.open(
      errorMessage ?? ConfirmBookingComponent.DEFAULT_PAYMENT_ERROR_MESSAGE,
      undefined,
      {
        duration: SNACK_BAR_AUTO_DISMISS_MILLISECONDS,
      },
    );
  }

  navigateToBookingSuccessPage(draftAppointment: DraftAppointmentSlot, serviceType: string): void {
    const companyBookingRoute = this.bookingStepService.getActivatedRouteAncestorOfComponentType(this.route, [CompanyBookingComponent]);
    this.bookingStepService.jumpToStep(BookingStep.SUCCESS, {
      navigationExtras: {
        relativeTo: companyBookingRoute,
        queryParams: {
          province: draftAppointment.province,
        },
      },
      pathParams: {
        doctorId: draftAppointment.doctorId,
        appointmentMedium: draftAppointment.serviceMedium,
        serviceId: draftAppointment.serviceId,
        serviceType: serviceType,
        startTime: draftAppointment.startTime.toString(),
      },
    });
  }

  navigateToSurvey(surveyUrl: string): void {
    window.location.assign(surveyUrl);
  }

  setDiscountedPrice(discountedPrice: number): void {
    this.discountedPrice = discountedPrice;
  }

  handlePaymentInformationChangeButtonClicked(): void {
    this.paymentInformationState = PaymentInformationState.UPDATE_CARD;
  }

  handlePaymentInformationCancelButtonClicked(): void {
    this.paymentInformationState = PaymentInformationState.USE_EXISTING_CARD;
  }

  handlePaymentSourceSaved(stripeCustomer: StripeCustomer): void {
    const paymentSource = stripeCustomer.sources[0];

    this.lastFourDigitsOfExistingPaymentCard = paymentSource.lastFourDigits;
    this.paymentInformationState = PaymentInformationState.USE_EXISTING_CARD;
  }

  shouldShowPaymentInformation(draftAppointment: DraftAppointmentSlot): boolean {
    return (draftAppointment.billingType === BillingType.PUBLIC && coerceBooleanProperty(draftAppointment.isServiceCharged) === true) || draftAppointment.billingType === BillingType.PRIVATE;
  }

  isBookNowButtonDisabled(
    paymentInformationState: PaymentInformationState,
    isBookForSomeoneElseSelected: boolean,
    selectedFamilyMemberProfile: FamilyMemberProfile | undefined,
    isBookingInstructionsRadioButtonChecked: boolean,
    isHealthCardInvalid: boolean,
    draftAppointment: DraftAppointmentSlot,
    prescriptionDeliveryState: PrescriptionDeliveryState,
  ): boolean {
    return paymentInformationState === PaymentInformationState.UPDATE_CARD ||
      (isBookForSomeoneElseSelected && !selectedFamilyMemberProfile) ||
      !isBookingInstructionsRadioButtonChecked ||
      (draftAppointment.billingType === BillingType.PUBLIC && isHealthCardInvalid) ||
      prescriptionDeliveryState === PrescriptionDeliveryState.EDITING_PHARMACY;
  }

  doErrorCodesBlockBooking(errorCodes: DraftErrorCode[]): boolean {
    const blockingErrorCodes = errorCodes.filter((errorCode) => !this.nonBlockingErrorCodes.includes(errorCode));
    return blockingErrorCodes.length > 0;
  }

  async handleSendNotesToFamilyDoctorCheckboxClicked($event: Event): Promise<void> {
    const accountHolderProfile = await firstValueFrom(this.accountHolderProfile$);
    if (!accountHolderProfile) {
      return;
    }

    if (this.isBookForSomeoneElseSelected && this.selectedFamilyMemberProfile) {
      this.selectedFamilyMemberProfile.prefersSendNoteToFamilyDoctor = ($event.target as HTMLInputElement).checked;
      await this.familyMemberService.setFamilyMember(accountHolderProfile.uid, this.selectedFamilyMemberProfile?.familyMemberId, this.selectedFamilyMemberProfile);
    } else {
      accountHolderProfile.prefersSendNoteToFamilyDoctor = ($event.target as HTMLInputElement).checked;
      await this.patientProfileService.setCurrentUserPatientProfile(accountHolderProfile);
    }

    this.setIsFamilyDoctorInvalid(this.isBookForSomeoneElseSelected, this.selectedFamilyMemberProfile, accountHolderProfile);
  }

  setIsFamilyDoctorInvalid(isBookForSomeoneElseSelected: boolean, selectedFamilyMemberProfile?: FamilyMemberProfile, accountHolderProfile?: PatientProfile): void {
    if (isBookForSomeoneElseSelected && selectedFamilyMemberProfile) {
      this.isFamilyDoctorInvalid = this.isProfileFamilyDoctorInvalid(selectedFamilyMemberProfile);
    } else if (accountHolderProfile) {
      this.isFamilyDoctorInvalid = this.isProfileFamilyDoctorInvalid(accountHolderProfile);
    }
  }

  isProfileFamilyDoctorInvalid(profile: PatientProfile | FamilyMemberProfile): boolean {
    const isPhoneNumberInvalid = this.formValidatorsService.isPhoneNumberValidValidator(false)({ value: profile.familyDoctorFaxNumber } as AbstractControl);
    const isFamilyDoctorFieldsFilledAndValid = !!profile.familyDoctorFullName && (!!profile.familyDoctorFaxNumber && !isPhoneNumberInvalid);
    return !!profile.prefersSendNoteToFamilyDoctor && !isFamilyDoctorFieldsFilledAndValid;
  }

  async handleFamilyDoctorSaved(): Promise<void> {
    this.updateFamilyMembersSubject.next();
  }

  getBookingInstructionsHeader(doctorSearchData: DoctorSearchData): string {
    if (doctorSearchData.doctorMetadata?.bookingInstructions) {
      return `${ConfirmBookingComponent.BOOKING_INSTRUCTION_CUSTOM_HEADER}${doctorSearchData.doctorMetadata.fullName}`;
    } else {
      return ConfirmBookingComponent.DEFAULT_BOOKING_INSTRUCTIONS_HEADER;
    }
  }

  getBookingInstructions(doctorSearchData: DoctorSearchData): string {
    return doctorSearchData.doctorMetadata?.bookingInstructions ?? ConfirmBookingComponent.DEFAULT_BOOKING_INSTRUCTIONS;
  }

  isTiaHealthFlow(route: ActivatedRoute): boolean {
    const companyBookingRoute = this.bookingStepService.getActivatedRouteAncestorOfComponentType(route, [CompanyBookingComponent]);

    return this.regexService.getTiaHealthRegex().test(companyBookingRoute.snapshot.params.companyId);
  }

  getPriceText(price: number): string {
    return `$${price}`;
  }

  getFormattedStartDateAndTime(utcTimestamp: number): string {
    const timezone = this.dateAndTimeService.getLocalTimeZone();
    const monthAndDayText = this.dateAndTimeService.getMonthAndDay(new Date(utcTimestamp), timezone);
    const hourAndMinuteText = this.dateAndTimeService.getHourMinuteAndPeriod(new Date(utcTimestamp), timezone);
    return `${monthAndDayText}, ${hourAndMinuteText}`;
  }

  handleDraftAppointmentErrors(draftAppointment: DraftAppointmentSlot): void {
    if (draftAppointment.errorCodes.includes(DraftErrorCode.INVALID_HEALTHCARD_CONTACT_MINISTRY)) {
      this.snackBar.open(ConfirmBookingComponent.INVALID_HEALTHCARD_CONTACT_MINISTRY_ERROR_MESSAGE, undefined, { duration: SNACK_BAR_AUTO_DISMISS_MILLISECONDS });
      this.isHealthCardInvalid = true;
    }
  }
}
