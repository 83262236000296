<insig-ui-card class="mb-2" *ngIf="(prescriptionDeliveryState$ | async) as prescriptionDeliveryState">
  <form *ngIf="(patientProfile$ | async) as patientProfile">
    <div role="radiogroup" class="d-flex flex-column gap-3 border-0 px-0">
      <span class="font-size-md bold">How do you want to receive your medications?<span class="textPink">*</span></span>
      <div class="d-flex flex-column gap-2">
        <div class="d-flex align-items-stretch w-100 rounded"
          [ngClass]="{
            'grayBorder': prescriptionDeliveryState !== PrescriptionDeliveryState.PILLWAY_SELECTED,
            'greenBorder': prescriptionDeliveryState === PrescriptionDeliveryState.PILLWAY_SELECTED,
          }"
        >
          <label 
            class="d-flex rounded w-100 p-2">
            <div class="d-flex px-2 px-lg-3">
              <input 
                type="radio"
                name="PrescriptionDeliveryState"
                [value]="PrescriptionDeliveryState.PILLWAY_SELECTED"
                (click)="handlePillwayClicked(patientProfile)"
              >
              <img
                class="my-auto" 
                width="30px" 
                [src]="
                  prescriptionDeliveryState === PrescriptionDeliveryState.PILLWAY_SELECTED ?
                    'assets/img/ico-checked-outlined.svg' :
                    'assets/img/ico-unchecked.svg'"
                alt=""
              >
            </div>
            <div class="d-flex flex-column">
              <strong class="font-size-lg textBlue">Prescription Delivery</strong>
              <strong class="text-break font-size-xs textSecondary"
              >
                Free delivery to your door, as fast as same day.
              </strong>
            </div>
          </label>

          <button
            type="button"
            class="p-2 font-size-sm bold text-nowrap textBlue"
            (click)="handlePillwayLearnMoreClicked()"
          >
            Learn more
          </button>
        </div>
  
        <div class="d-flex align-items-stretch w-100 rounded"
          [ngClass]="{
            'grayBorder': prescriptionDeliveryState === PrescriptionDeliveryState.PILLWAY_SELECTED,
            'greenBorder': prescriptionDeliveryState !== PrescriptionDeliveryState.PILLWAY_SELECTED,
          }"
        >
          <label 
            class="d-flex flex-fill align-items-center border-end-0 my-2 my-md-0 px-2 py-1 p-md-2">
            <div class="d-flex px-2 px-lg-3">
              <input
                type="radio"
                name="PrescriptionDeliveryState"
                [value]="PrescriptionDeliveryState.EXTERNAL_PHARMACY_SELECTED"
                (click)="handleExternalPharmacyClicked()"
              >
              <img
                class="my-auto"
                width="30px"
                [src]="
                  prescriptionDeliveryState !== PrescriptionDeliveryState.PILLWAY_SELECTED ?
                    'assets/img/ico-checked-outlined.svg' :
                    'assets/img/ico-unchecked.svg'"
                alt=""
              >
            </div>
            <strong class="font-size-sm text-wrap textSecondary">
              {{ patientProfile | externalPharmacyTextPipe }}
            </strong>
          </label>
  
          <button
            *ngIf="prescriptionDeliveryState === PrescriptionDeliveryState.EXTERNAL_PHARMACY_SELECTED"
            class="d-flex gap-2 p-3 align-items-center"
            (click)="handleEditClicked()"
          >
            <img class="d-block" src="assets/img/ico-edit-pencil.svg" alt="">
            <span class="d-none d-sm-block font-size-sm bold textBlue">Edit</span>
          </button>
        </div>
      </div>
    </div>
  </form>

  <ng-container *ngIf="prescriptionDeliveryState === PrescriptionDeliveryState.EDITING_PHARMACY">
    <hr class="w-100 my-4">
    <insig-booking-pharmacy-stand-alone-form></insig-booking-pharmacy-stand-alone-form>
  </ng-container>
</insig-ui-card>
