import { inject, Injectable, Pipe, PipeTransform } from '@angular/core';
import { PrescriptionDeliveryService } from '../../services/prescription-delivery/prescription-delivery.service';
import { PatientProfile } from '@insig-health/services/patient-profile/patient-profile.service';

@Pipe({
  standalone: true,
  name: 'externalPharmacyTextPipe',
})
@Injectable()
export class ExternalPharmacyTextPipe implements PipeTransform {
  private pillwayService = inject(PrescriptionDeliveryService);

  transform(patientProfile: PatientProfile): string {
    const isPatientPharmacyPillway = this.pillwayService.isPatientPharmacyPillway(patientProfile);
    const { pharmacyName, pharmacyFax } = patientProfile;
    const doesPatientHavePharmacyInfo = pharmacyName !== '' && pharmacyFax !== '';

    return isPatientPharmacyPillway || !doesPatientHavePharmacyInfo ?
      'Pickup at an external pharmacy' :
      `Pickup at ${pharmacyName} with fax number ${pharmacyFax}`;
  }
}
