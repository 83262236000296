import { Component, OnDestroy, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PatientProfile, PatientProfileService } from '@insig-health/services/patient-profile/patient-profile.service';
import { PillwayLearnMoreDialogComponent } from '../../dialogs/pillway-learn-more-dialog/pillway-learn-more-dialog.component';
import { filter, firstValueFrom, map } from 'rxjs';
import { PrescriptionDeliveryState, PrescriptionDeliveryService } from '../../services/prescription-delivery/prescription-delivery.service';
import { PillwayOptOutDialogComponent, PillWayOptOutDialogResult } from '../../dialogs/pillway-opt-out-dialog/pillway-opt-out-dialog.component';

@Component({
  selector: 'insig-booking-prescription-delivery',
  templateUrl: './prescription-delivery.component.html',
  styleUrls: ['./prescription-delivery.component.scss'],
})
export class PrescriptionDeliveryComponent implements OnDestroy {
  private readonly dialog = inject(MatDialog);
  private readonly patientProfileService = inject(PatientProfileService);
  private readonly prescriptionDeliveryService = inject(PrescriptionDeliveryService);

  public readonly PrescriptionDeliveryState = PrescriptionDeliveryState;
  public readonly PRESCRIPTION_DELIVERY_FORM_NAME = 'prescriptionDeliveryForm';

  public patientProfile$ = this.patientProfileService.getCurrentUserPatientProfile();
  public prescriptionDeliveryState$ = this.prescriptionDeliveryService.getPrescriptionDeliveryState();

  public patientPharmacyUpdatedSubscription = this.patientProfile$.pipe(
    filter((patientProfile): patientProfile is PatientProfile => patientProfile !== undefined),
    map((patientProfile) => this.prescriptionDeliveryService.isPatientPharmacyPillway(patientProfile)),
  ).subscribe((isPatientPharmacyPillway) => {
    this.handlePatientPharmacyUpdated(isPatientPharmacyPillway);
  });

  ngOnDestroy(): void {
    this.patientPharmacyUpdatedSubscription.unsubscribe();
  }

  private handlePatientPharmacyUpdated(isPatientPharmacyPillway: boolean): void {
    const updatedState = isPatientPharmacyPillway ?
      PrescriptionDeliveryState.PILLWAY_SELECTED :
      PrescriptionDeliveryState.EXTERNAL_PHARMACY_SELECTED;

    this.prescriptionDeliveryService.setPrescriptionDeliveryState(updatedState);
  }

  handlePillwayLearnMoreClicked(): void {
    this.dialog.open<PillwayLearnMoreDialogComponent>(
      PillwayLearnMoreDialogComponent,
      PillwayLearnMoreDialogComponent.DEFAULT_DIALOG_CONFIG,
    );
  }

  async handlePillwayClicked(patientProfile: PatientProfile): Promise<void> {
    try {
      await this.prescriptionDeliveryService.patchPatientProfileWithPillwayPharmacyInfo(patientProfile);
      this.prescriptionDeliveryService.setPrescriptionDeliveryState(PrescriptionDeliveryState.PILLWAY_SELECTED);
    } catch (error) {
      console.error(error);
    }
  }

  async handleExternalPharmacyClicked(): Promise<void> {
    const currentState = await firstValueFrom(this.prescriptionDeliveryService.getPrescriptionDeliveryState());
    if (currentState !== PrescriptionDeliveryState.PILLWAY_SELECTED) {
      return;
    }

    const { isOptedOutForPillway } = await this.openPillwayOptOutDialog();
    if (!isOptedOutForPillway) {
      return;
    }

    this.prescriptionDeliveryService.setPrescriptionDeliveryState(PrescriptionDeliveryState.EDITING_PHARMACY);
  }

  private openPillwayOptOutDialog(): Promise<PillWayOptOutDialogResult> {
    const dialogRef = this.dialog.open<PillwayOptOutDialogComponent, PillWayOptOutDialogResult>(
      PillwayOptOutDialogComponent,
      PillwayOptOutDialogComponent.DEFAULT_DIALOG_CONFIG,
    );

    return firstValueFrom(dialogRef.afterClosed());
  }

  handleEditClicked(): void {
    this.prescriptionDeliveryService.setPrescriptionDeliveryState(PrescriptionDeliveryState.EDITING_PHARMACY);
  }
}
