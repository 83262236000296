<p class="h1" matDialogTitle class="pt-0">Are you sure you want to switch to an external pharmacy?</p>

<div mat-dialog-content class="d-flex flex-column gap-2 pb-2">
  <span class="font-size-sm pb-2">
    Prescription delivery from Tia includes:
  </span>

  <ul class="d-flex flex-column gap-4">
    <li>
      <span class="font-size-lg bold textGreen pe-2">&check;</span>
      <span class="font-size-sm">
        Free shipping to your door
      </span>
    </li>
    <li>
      <span class="font-size-lg bold textGreen pe-2">&check;</span>
      <span class="font-size-sm">
        As fast as same day delivery
      </span>
    </li>
    <li>
      <span class="font-size-lg bold textGreen pe-2">&check;</span>
      <span class="font-size-sm">
        Check status updates through the Pillway app
      </span>
    </li>
    <li>
      <span class="font-size-lg bold textGreen pe-2">&check;</span>
      <span class="font-size-sm">
        Pause or cancel anytime
      </span>
    </li>
    <li>
      <span class="font-size-lg bold textGreen pe-2">&check;</span>
      <span class="font-size-sm">
        Easy prescription renewal
      </span>
    </li>
  </ul>

  <hr class="w-100">

  <span class="font-size-sm">
    For more information, learn more <a href="https://pillway.com/" target="_blank" rel="noopener" class="bold">here</a>
  </span>
</div>

<div mat-dialog-actions class="d-flex mt-4 justify-content-between">
  <insig-ui-button (click)="handleCancelClicked()" stylePreset="tertiary">Cancel</insig-ui-button>
  <insig-ui-button (click)="handleConfirmClicked()" stylePreset="primary">Confirm</insig-ui-button>
</div>
