<p class="h1" matDialogTitle class="pt-0">How does prescription delivery work?</p>

<div mat-dialog-content class="d-flex flex-column gap-2 pb-2">
  <ul class="d-flex flex-column gap-4">
    <li>
      <span class="font-size-lg bold textGreen pe-2">&check;</span>
      <span class="font-size-sm">
        Same day or next business day delivery, for free
      </span>
    </li>
    <li>
      <span class="font-size-lg bold textGreen pe-2">&check;</span>
      <span class="font-size-sm">
        Your prescription will be sent to Pillway, a registered Canadian pharmacy and fulfillment partner
      </span>
    </li>
    <li>
      <span class="font-size-lg bold textGreen pe-2">&check;</span>
      <span class="font-size-sm">
        Pillway will contact you within 1 business hour of the prescription being sent to arrange delivery
      </span>
    </li>
    <li>
      <span class="font-size-lg bold textGreen pe-2">&check;</span>
      <span class="font-size-sm">
        Most prescriptions are available with the exception of controlled substances
      </span>
    </li>
  </ul>

  <hr class="w-100">

  <span class="font-size-sm">
    For more information, learn more <a href="https://pillway.com/" target="_blank" rel="noopener" class="bold">here</a>
  </span>
</div>

<div mat-dialog-actions class="d-flex mt-4">
  <insig-ui-button mat-dialog-close stylePreset="primary" class="mx-auto">Close</insig-ui-button>
</div>
