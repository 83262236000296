import { inject, Injectable } from '@angular/core';
import { SuccessResponse } from '@insig-health/api/patient-management-api';
import { PatientProfile, PatientProfileService } from '@insig-health/services/patient-profile/patient-profile.service';
import { Province, ProvinceService } from '@insig-health/services/province/province.service';
import { RegexService } from '@insig-health/services/regex/regex.service';
import { BehaviorSubject, Observable } from 'rxjs';

export enum PrescriptionDeliveryState {
  PILLWAY_SELECTED = 'PILLWAY_SELECTED',
  EXTERNAL_PHARMACY_SELECTED = 'EXTERNAL_PHARMACY_SELECTED',
  EDITING_PHARMACY = 'EDITING_PHARMACY',
}

export enum PILLWAY_FAX {
  WEST = '6046304949',
  EAST = '8337455929',
}

export const PILLWAY_PHARMACY_NAME = 'Pillway';

@Injectable({
  providedIn: 'root',
})
export class PrescriptionDeliveryService {
  private readonly patientProfileService = inject(PatientProfileService);
  private readonly provinceService = inject(ProvinceService);

  private _prescriptionDeliveryState$ = new BehaviorSubject<PrescriptionDeliveryState>(PrescriptionDeliveryState.PILLWAY_SELECTED);

  getPrescriptionDeliveryState(): Observable<PrescriptionDeliveryState> {
    return this._prescriptionDeliveryState$.asObservable();
  }

  setPrescriptionDeliveryState(state: PrescriptionDeliveryState): void {
    this._prescriptionDeliveryState$.next(state);
  }

  isPatientPharmacyPillway(patientProfile: PatientProfile): boolean {
    const patientPharmacyFax = patientProfile.pharmacyFax.replace(RegexService.NON_E164_PHONE_NUMBER_CHARACTER_REGEX, '');
    const isPatientPharmacyFaxPillway =
      patientPharmacyFax === PILLWAY_FAX.WEST ||
      patientPharmacyFax === PILLWAY_FAX.EAST;
    return patientProfile.pharmacyName === PILLWAY_PHARMACY_NAME && isPatientPharmacyFaxPillway;
  }

  async patchPatientProfileWithPillwayPharmacyInfo(patientProfile: PatientProfile): Promise<SuccessResponse> {
    const patientProvince = this.provinceService.parseQueryParamProvince(patientProfile.province);
    return this.patientProfileService.setCurrentUserPatientProfile({
      pharmacyName: PILLWAY_PHARMACY_NAME,
      pharmacyFax: this.getPillwayFax(patientProvince),
    });
  }

  private getPillwayFax(province: Province): string {
    return [Province.BC, Province.AB].includes(province) ?
      PILLWAY_FAX.WEST :
      PILLWAY_FAX.EAST;
  }
}
