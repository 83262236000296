import { Component, inject } from '@angular/core';
import { MatDialogConfig, MatDialogRef } from '@angular/material/dialog';

export type PillWayOptOutDialogResult = {
  isOptedOutForPillway: boolean;
}

@Component({
  selector: 'insig-booking-pillway-opt-out-dialog',
  templateUrl: './pillway-opt-out-dialog.component.html',
})
export class PillwayOptOutDialogComponent {
  static readonly DIALOG_MAX_WIDTH = '600px';
  static readonly DIALOG_WIDTH = '100vw';

  static readonly DEFAULT_DIALOG_ID = 'insig-booking-pillway-opt-out-dialog-id';
  static readonly DEFAULT_DIALOG_CONFIG: MatDialogConfig = {
    id: PillwayOptOutDialogComponent.DEFAULT_DIALOG_ID,
    width: PillwayOptOutDialogComponent.DIALOG_WIDTH,
    maxWidth: PillwayOptOutDialogComponent.DIALOG_MAX_WIDTH,
    disableClose: true,
  };

  private readonly dialogRef = inject<MatDialogRef<PillwayOptOutDialogComponent, PillWayOptOutDialogResult>>(MatDialogRef<PillwayOptOutDialogComponent, PillWayOptOutDialogResult>);

  handleCancelClicked(): void {
    this.dialogRef.close({ isOptedOutForPillway: false });
  }

  handleConfirmClicked(): void {
    this.dialogRef.close({ isOptedOutForPillway: true });
  }
}
